.searchresultpanel-container {
	background-color: white;
	margin-top: 20px;
	padding: 2rem;
	border-radius: 13px;
}
.MuiTablePagination-selectLabel {
	margin: auto;
}
.MuiTablePagination-displayedRows {
	margin: auto;
}
